/**
 * @generated SignedSource<<2c4c3a6a5aeb6bddf30c30432c00be56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AIJourneySettingsDefaultsPageQuery$variables = {
  companyId: string;
};
export type AIJourneySettingsDefaultsPageQuery$data = {
  readonly company: {
    readonly " $fragmentSpreads": FragmentRefs<"DefaultCardsSectionFragment_company">;
  } | null;
};
export type AIJourneySettingsDefaultsPageQuery = {
  response: AIJourneySettingsDefaultsPageQuery$data;
  variables: AIJourneySettingsDefaultsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "emptyUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIJourneySettingsDefaultsPageQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DefaultCardsSectionFragment_company"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIJourneySettingsDefaultsPageQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "vendor",
                    "value": "SHOPIFY"
                  }
                ],
                "concreteType": "CompanyVendorIntegration",
                "kind": "LinkedField",
                "name": "companyVendorIntegrations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalVendorApi",
                    "kind": "LinkedField",
                    "name": "externalVendorApi",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vendor",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": "companyVendorIntegrations(vendor:\"SHOPIFY\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoJourney",
                "kind": "LinkedField",
                "name": "unifiedAutoJourney",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AIJourneyLinkConfiguration",
                    "kind": "LinkedField",
                    "name": "aiJourneyLinkTypeConfigurations",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "linkConfig",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "linkConfig",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v5/*: any*/)
                                    ],
                                    "type": "CustomLinkConfig",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v6/*: any*/),
                                    "type": "CouponDisplayScreenConfig",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v6/*: any*/),
                                    "type": "OfferLinkConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "WelcomeLinkConfiguration",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomLinkConfig",
                                "kind": "LinkedField",
                                "name": "customLink",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "AbandonmentLinkConfiguration",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AIJourneyDefaultMessage",
                    "kind": "LinkedField",
                    "name": "aiJourneyDefaultMessages",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "messageContent",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29e4e069ea029141464f422ae0d2311e",
    "id": null,
    "metadata": {},
    "name": "AIJourneySettingsDefaultsPageQuery",
    "operationKind": "query",
    "text": "query AIJourneySettingsDefaultsPageQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      ...DefaultCardsSectionFragment_company\n    }\n    id\n  }\n}\n\nfragment DefaultCardsSectionFragment_company on Company {\n  companyVendorIntegrations(vendor: \"SHOPIFY\") {\n    status\n    externalVendorApi {\n      vendor\n      id\n    }\n    id\n  }\n  unifiedAutoJourney {\n    aiJourneyLinkTypeConfigurations {\n      messageType\n      linkConfig {\n        __typename\n        ... on WelcomeLinkConfiguration {\n          linkConfig {\n            __typename\n            ... on CustomLinkConfig {\n              __typename\n              url\n            }\n            ... on CouponDisplayScreenConfig {\n              __typename\n              emptyUrl\n            }\n            ... on OfferLinkConfig {\n              __typename\n              emptyUrl\n            }\n          }\n        }\n        ... on AbandonmentLinkConfiguration {\n          customLink {\n            __typename\n            url\n          }\n        }\n      }\n    }\n    aiJourneyDefaultMessages {\n      id\n      messageContent\n      messageType\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "09953977accd453ce7725b6888ed17bc";

export default node;
